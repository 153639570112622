:root {
  --num-columns: 3; /* Default number of columns */
  --row-height: 120px; /* Height of each row */
  --animation-speed: 2s; /* Default animation speed */
}

.slot-machine {
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f5f5f5;
  overflow: hidden;
  width: 100%;
}

.slots {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: calc(var(--row-height) * 3); /* Only 3 visible rows */
}


.slot-element {
  width: 100%;
  height: var(--row-height); /* Each slot height */
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
}

.slot-element img {
  max-width: 90%;
  max-height: 90%;
}

@keyframes spin {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(calc(-1 * var(--row-height) * 100)); /* Total height for 100 rows */
  }
}

.slot-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc((100% - (var(--num-columns) - 1) * 20px) / var(--num-columns));

  animation-timing-function: ease-out;
  animation-iteration-count: 1;
  animation-duration: var(--animation-speed);
}
.slot-element.highlight {
  background-color: rgba(255, 215, 0, 0.5); /* Yellow highlight */
  border: 2px solid #ffd700; /* Gold border */
  box-shadow: 0 0 10px rgba(255, 215, 0, 0.8); /* Glowing effect */
  transition: background-color 0.3s ease, border 0.3s ease, box-shadow 0.3s ease;
}

.slot-element.highlight img {
  transform: scale(1.1); /* Slight zoom effect */
  transition: transform 0.3s ease; /* Smooth zoom effect */
}



.visible {
  background-color: rgba(255, 255, 0, 0.5); /* Highlight winning row */
}

.wheelContainer {
  position: relative;
  width: 100%;
  height: 400px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.white-line-vertical {
  position: absolute;
  width: 2px; /* Set the width to create a vertical line */
  height: 100%; /* Set the height to cover the entire height of the container */
  background-color: #132026;
  left: 50%; /* Position the line in the middle horizontally */
  transform: translateX(-50%);
}

/* Responsive Styles */
@media (max-width: 600px) {
  :root {
    --row-height: 80px; /* Adjust row height for smaller screens */
  }

  .slot-machine {
    border-width: 2px; /* Thinner border for smaller screens */
    border-radius: 5px; /* Reduced border radius */
  }

  .slots {
    height: calc(var(--row-height) * 3); /* Maintain 3 visible rows */
  }

  .slot-element {
    height: var(--row-height); /* Adjust slot height */
    border-width: 1px; /* Thinner borders for smaller screens */
  }

  .slot-element img {
    max-width: 80%; /* Slightly smaller images */
    max-height: 80%;
  }

  .slot-column {
    width: calc((100% - (var(--num-columns) - 1) * 10px) / var(--num-columns));
    /* Adjust spacing for smaller screens */
  }

  .slot-element.highlight {
    box-shadow: 0 0 5px rgba(255, 215, 0, 0.6); /* Softer glow effect */
  }

  .wheelContainer {
    height: 300px; /* Reduced height for smaller screens */
  }
}
