body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'SonsieOne-Regular';
  src: local('SonsieOne-Regular'), url(./assets/fonts/SonsieOne-Regular.ttf) format('truetype');
}

/* Bernier Shade */
@font-face {
  font-family: 'BernierShade';
  src: url('./assets/fonts/BERNIER™ Shade.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Bradley Hand Bold */
@font-face {
  font-family: 'BradleyHandBold';
  src: url('./assets/fonts/Bradley Hand Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

/* Corbel Regular */
@font-face {
  font-family: 'Corbel';
  src: url('./assets/fonts/Corbel.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Corbel Bold */
@font-face {
  font-family: 'Corbel';
  src: url('./assets/fonts/Corbel Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
