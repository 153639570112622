/* styles.css */

:root {
  --numberOfRectangles: 16;
}

/* body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    background-color: #f0f0f0;
  } */

.rootContainer {
  display: flex;
  /* Use flexbox for centering */
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
  transform: translateY(250px);
}

.container {
  position: absolute;
  background-color: #333;
  border: 2px solid #333;
  border-radius: 50%;
  /* top: 100; */
  /* animation: rotateContainer 10s linear infinite;  */
  /* Circular rotation animation */
  transition: transform 5s ease-out;  
  transform: rotate(0deg); /* Explicit initial value */
}

.rectangle img {
  max-width: 45%;
  max-height: 45%;
  width: auto;
  height: auto;
  transition: transform 0.3s;
  /* Add a smooth transition effect */
}

.rectangle img:hover {
  transform: translateY(-10px);
  /* Move the image up by 10px on hover */
}

@keyframes rotateContainer {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.rectangle {
  position: absolute;
  transform-origin: center bottom;
  transform: translateX(-50%) translateY(0%);
  text-align: center;
  display: flex;
  /* Use flexbox for centering */
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
  /* clip-path: polygon(8% 10%, 50% 8%, 92% 10%, 74% 100%, 26% 100%); */




  /* clip-path: polygon(8% 10%, 25% 3%, 50% 0%, 77% 3%, 92% 10%, 74% 100%, 26% 100%); */
  /* clip-path: polygon(8% 10%, 50% 8%, 92% 10%, 74% 100%, 26% 100%); */
  /* clip-path: path("M63.19,96.02L81.53,3.81C55.32-1.16,27.76-1.4,0,3.87L18.34,96.06c15.26-2.79,30.41-2.68,44.85-") */
  /* clip-path: path("M159 289.25 195.75 6.5s-49.499-6-97.875-6S0 6.5 0 6.5l37.5 282.75s30.75-3 60.75-3.75c30.001-.75 60.75 3.75 60.75 3.75Z") */


}


/* .container.spin {
    animation: spinContainer 5s cubic-bezier(0.36, 1.56, 0.64, -0.35) infinite;
  }
  
@keyframes spinContainer {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} */

.container.spin {
  /* animation: spinContainer 2s cubic-bezier(0.36, 1.56, 0.64, -0.35) infinite; */
  transition: transform 5s ease-in-out;
}

@keyframes spinContainer {
  0% {
    transform: rotate(0deg);
  }

  85% {
    transform: rotate(3600deg);
    /* Rotate quickly for 85% of the animation duration */
  }

  100% {
    transform: rotate(3600deg);
    /* Hold the rotation for 15% of the animation duration (7 seconds) */
  }
}


.wheel {
  fill: transparent;
  stroke: transparent;
  stroke-width: 2;
}

.hidden {
  display: none;
}


@media (max-width: 500px) {
  .rootContainer {
    display: flex;
    /* Use flexbox for centering */
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    transform: translateY(75px);
  }
}